import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93RarigGr0y6Meta } from "/app/pages/characters/people/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93JuURFkbyPgMeta } from "/app/pages/history/materials/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93iN9RmcXwSeMeta } from "/app/pages/homebrew/items/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93FD8CZe8w4mMeta } from "/app/pages/homebrew/spells/[...slug].vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "characters-people-slug",
    path: "/characters/people/:slug(.*)*",
    component: () => import("/app/pages/characters/people/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "history-materials-slug",
    path: "/history/materials/:slug(.*)*",
    component: () => import("/app/pages/history/materials/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "homebrew-items-slug",
    path: "/homebrew/items/:slug(.*)*",
    component: () => import("/app/pages/homebrew/items/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "homebrew-spells-slug",
    path: "/homebrew/spells/:slug(.*)*",
    component: () => import("/app/pages/homebrew/spells/[...slug].vue").then(m => m.default || m)
  }
]